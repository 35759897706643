import { createApp } from 'vue'
import App from './App.vue'
import './icons'
const app = createApp(App)

import router from './router'
app.use(router)

import store from './store'
app.use(store)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
app.use(ElementPlus, {
    locale: zhCn,
})

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

import util from './utils'
app.config.globalProperties.$util = util

import axios from './axios';
app.config.globalProperties.$axios = axios

import filter from './filter'
app.config.globalProperties.$filter = filter


import SvgIcon from '@/components/svgIcon'
app.component("svg-icon",SvgIcon)

app.mount('#app')





