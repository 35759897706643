import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from '@/store'
const routes = [
    {
        path: "/",
        name: "nav",
        component: ()=>import('@/views/nav'),
    },
    {
        path: "/townNav",
        name: "townNav",
        component: ()=>import('@/views/town'),
    }
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
})

let flag = false
router.beforeEach(async (to, from, next)=>{
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.name === 'admin') {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
        }
        flag = true
        next()
    } else if (!flag) {
        let adminRoute = JSON.parse(JSON.stringify(store.getters.getData('vuex_routes')))
        if(adminRoute && adminRoute.length > 0) {
            adminRoute.forEach(item=>{
                router.addRoute('admin', {
                    path: item.path,
                    name: item.name,
                    component: ()=>import('@/views'+item.component)
                })
            })
            flag = true
            next({
                ...to,
                replace: true
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

export function resetRouter(router) {

}

export default router
